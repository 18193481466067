export enum PolicyStatus {
  POLICY_ACTIVE = "Active",
  POLICY_PROSPECT = "Prospect",
  POLICY_BLACKLIST = "Blacklist",
  POLICY_CANCELLED = "Cancelled",
}

/* Policy not registered
Policy Payment Not Registered
Cancellation
Renewal
Reconciliation */
