/**
 * @description Remove empty, null or undefined keys in objects
 * Take from: https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
 * @param object Object to remove clean keys
 * @example
 * ```javascript
 *  clear({
 *    foo: "",
 *    bar: 1
 * }) // => {bar: 1}
 * ```
 */
function cleanObject(object: any): any {
  const propNames = Object.getOwnPropertyNames(object);

  for (let i = 0; i < propNames.length; i++) {
    const key = propNames[i];

    if (
      object[key] === null ||
      object[key] === undefined ||
      object[key] === 0
    ) {
      delete object[key];
    }
  }

  return object;
}

export default cleanObject;
