
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "btn-request-patients",
  computed: {
    ...mapState("crmMedicFormModule", ["loading", "reqrange"]),
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListPatients"]),
    requestPatients(): void {
      this.actListPatients(this.reqrange);
    },
  },
});
