<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            class="mx-1"
            icon
            depressed
            color="info"
            v-bind="attrs"
            v-on="{ ...dialog, ...tooltip }"
            dark
            ><v-icon>mdi-shuffle-variant</v-icon></v-btn
          >
        </template>
        <span>Change Status</span>
      </v-tooltip>
    </template>
    <template>
      <v-card>
        <v-toolbar color="info" dark>Change Status</v-toolbar>
        <v-card-text class="d-flex justify-center mt-2">
          <v-autocomplete
            class="mx-1 my-3"
            :label="`Select Status`"
            :items="policyStates"
            v-model="statusId"
            item-text="label"
            item-value="value"
            style="max-width: 350px"
            prepend-inner-icon="mdi-account-search"
            clearable
            hide-details
            outlined
            dense
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cancel()">Close</v-btn>
          <v-btn
            :disabled="
              statusId == '' || statusId == null || loadingAction || !validNote
            "
            :loading="loadingAction"
            color="info"
            text
            @click="confirm()"
            >Change</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-dialog
        v-model="dialogNote"
        persistent
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="primary white--text">
            Note for Status Change:
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model.trim="note"
              class="my-3"
              placeholder="Write a note"
              outlined
              counter="240"
            />
          </v-card-text>

          <template v-if="!validNote">
            <div class="mx-3">
              <v-alert dense outlined type="error">
                The note must be between 5 and 240 characters
              </v-alert>
            </div>
          </template>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="error" text @click="cancelNote">cancel</v-btn>
            <v-btn
              color="success"
              :disabled="!validNote"
              text
              @click="acceptNote"
              >accept</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-dialog>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default Vue.extend({
  props: {
    clientsUuids: [],
  },
  name: "crm-change-status",
  data() {
    return {
      dialog: false,
      statusId: "",
      note: "",
      loading: false,
      loadingAction: false,
      isAdmins: false,
      dialogNote: false,
    };
  },
  watch: {
    statusId(val) {
      if (val) {
        this.dialogNote = true;
        this.note = "";
      }
    },
  },

  computed: {
    ...mapGetters(["isSuper", "isAdmin", "isSupervisor", "getCoordinatorN"]),
    ...mapState("crmEmployeeModule", ["workers"]),
    validNote() {
      if (this.note == "" || this.note == null || this.note == undefined) {
        return false;
      }
      if (this.note.length < 5 || this.note.length > 240) {
        return false;
      }
      return true;
    },
    policyStates() {
      const states = this.isAdmins
        ? [
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "PAYMENT_PENDING", label: "Payment Pending" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ]
        : [
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ];
      return states;
    },
  },
  mounted() {
    if (this.workers.length == 0) {
      this.actGetEmployees();
    }
    if (this.isSuper || this.isAdmin || this.isSupervisor) {
      this.isAdmins = true;
    }
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmConfigurationsModule", [
      "actAsignToLead",
      "actMultiAsignToLead",
    ]),

    cancel() {
      this.dialog = false;
      this.statusId = "";
      this.note = "";
      this.loadingAction = false;
    },

    async confirm() {
      const clients = this.clientsUuids.map((c) => c.uuid);
      let body = {
        patientUuids: clients,
        noteForStatusChange: this.note,
        polizeStatus: this.statusId,
      };
      this.loadingAction = true;
      getAPI
        .post("/patient/updateStatusMultiples", body)
        .then(() => {
          notifyInfo(this.$t("Status has been changed"));
          this.cancel();
          this.$emit("updated");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingAction = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancelNote() {
      this.dialogNote = false;
      this.note = "";
    },
    acceptNote() {
      this.dialogNote = false;
    },
  },
});
</script>
<style lang="scss" scope></style>
